import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-no-entries-card',
  templateUrl: './no-entries-card.component.html',
  styleUrls: ['./no-entries-card.component.scss'],
})
export class NoEntriesCardComponent implements OnInit {

  constructor() {
    
  }

  ngOnInit() {}

}