import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { SettingsService } from "src/app/services/core/settings.service";

@Injectable({
  providedIn: 'root'
})
export class FontsService {

  loadedFonts: any = {};

  readerFont: font = window.localStorage.getItem('readerFont') ? JSON.parse(window.localStorage.getItem('readerFont')) : null;

  constructor(
    private AppCMS: AppcmsService,
    private settings: SettingsService,
  ) {
  }

  getAll() {
    return this.AppCMS.loadUrl('./assets/fonts.json');
  }

  getReaderFont() {
    return this.settings.getSetting('font');
  }
  
  loadFontToDocument(font: font) {
    if(!this.loadedFonts.hasOwnProperty(font.uid)) {
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = `https://fonts.googleapis.com/css2?family=${font.name}&display=swap`;

      document.head.appendChild(link);
      
      this.loadedFonts[font.uid] = font;
    }
  }

  setReaderFont(font: font) {
    return this.settings.updateSetting('font', font);
  }

}