<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <!--
    <ion-buttons slot="end">
      <ion-button (click)="upload()" fill="clear" color="dark">
        <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'upload'|translate"></ion-label>
      </ion-button>
    </ion-buttons>
    -->

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-searchbar autocorrect="on" [(ngModel)]="search.query" #searchInput id="searchInput"
      [placeholder]="'search_something'|translate" (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>

  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-segment [(ngModel)]="view.segment" value="tasks" (click)="segmentChanged()">
      
      <ion-segment-button value="tasks">
        <ion-icon name="terminal-outline"></ion-icon>
        <ion-label [innerHTML]="'ai_tasks'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="shortcodes">
        <ion-icon name="code-download-outline"></ion-icon>
        <ion-label [innerHTML]="'ai_shortcodes'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <pipeline-selection-toolbar [(items)]="view.items" [options]="view.selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <pipeline-no-entries-card *ngIf="view.items && !view.items.length && !view.loading"></pipeline-no-entries-card>

    <ion-grid>

      <ion-row>

        <!-- sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !!view.task">

          <!-- Prompt types-->
          <ion-card [hidden]="view.segment !== 'shortcodes'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'types'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let promptType of view.promptTypes; trackBy:trackItems" fill="clear" color="dark" expaned="block">
              <ion-icon *ngIf="!!promptType.icon" [name]="promptType.icon" slot="start"></ion-icon>
              <ion-label [innerHTML]="promptType.name|translate"></ion-label>
            </ion-button>

          </ion-card>

          <!-- job titles -->
          <ion-card [hidden]="view.segment !== 'tasks'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'job_titles'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let job_title of view.job_titles; trackBy: trackItems" fill="clear" color="dark" expaned="block">
              <ion-label [innerHTML]="job_title.name|translate"></ion-label>
            </ion-button>

          </ion-card>

          <!-- industries -->
          <ion-card [hidden]="view.segment !== 'tasks'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'industries'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let industry of view.industries; trackBy: trackItems" fill="clear" color="dark"
              expaned="block">
              <ion-label [innerHTML]="industry.name|translate"></ion-label>
            </ion-button>

          </ion-card>

        </ion-col>

        <!-- content -->
        <ion-col [size]="view.isDesktop ? 9 : 12">

          <ion-spinner [hidden]="!!view.items"></ion-spinner>

          <ion-grid *ngIf="!!view.items && !!view.items.length">
            <ion-row>

              <!-- results -->
              <ion-col [size]="view.isDesktop ? 4 : 12" [hidden]="!view.isDesktop && !!view.task">

                <ion-card *ngFor="let task of view.items; let i = index; trackBy: trackItems">

                  <ion-item lines="none">

                    <ion-icon [name]="task.icon" *ngIf="!!task.icon" slot="start"></ion-icon>

                    <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="task.checked" (ionChange)="onItemChecked(task)" [disabled]="task.disabled" [innerHTML]="task.name" class="ion-text-wrap"></ion-checkbox>

                    <ion-button slot="end" fill="clear" color="dark" icon-only (click)="expand(task)">
                      <ion-icon [name]="task.expanded ? 'chevron-up' : 'chevron-down'"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </ion-col>

              <!-- item preview -->
              <ion-col [size]="view.isDesktop ? 8 : 12" *ngIf="!!view.task">

                <ion-card>

                  <ion-item>

                    <ion-button slot="start" color="dark" fill="clear" (click)="deselectItem()" [hidden]="!!view.isDesktop" icon-only>
                      <ion-icon name="arrow-back-outline"></ion-icon>
                    </ion-button>

                    <ion-label class="ion-text-wrap">
                      <h1 [innerHTML]="view.task.name"></h1>
                    </ion-label>

                  </ion-item>

                  <ion-item lines="none" *ngIf="!!view.task.input" class="ion-text-wrap">
                    <ion-label [innerHTML]="view.task.input" class="ion-text-wrap"></ion-label>
                  </ion-item>

                  <ion-item lines="none">
                    
                    <ion-label>
                      <p [innerHTML]="(!!view.task.coins ? (view.task.coins + ' ' + ('token'|translate)) : ('free'|translate))"></p>
                    </ion-label>

                    <ion-button [hidden]="!!view.task.coins" slot="end" shape="round" color="primary" (click)="import(view.task)">
                      <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'import'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                    <ion-button [hidden]="!view.task.coins" slot="end" shape="round" color="primary" (click)="buy(view.task)">
                      <ion-icon name="card-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'buy'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>

                <ion-card *ngIf="!!view.task.children && !!view.task.children.length">

                  <ion-card-header>
                    <ion-card-title [innerHTML]="'workflow'|translate"></ion-card-title>
                  </ion-card-header>
                  
                  <ion-list>
                    <ion-item *ngFor="let child of view.task.children; let iSub = index; trackBy:trackItems">
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="('step'|translate) + ' ' + (iSub+1)"></h3>
                        <h3 [innerHTML]="(child.name || 'no_name')|translate"></h3>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                
                </ion-card>

              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>