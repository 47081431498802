<ion-card [class]="'introCard ' + (view.cssClass || '')" [hidden]="view.hidden === true">

  <ion-button *ngIf="view.dismissable !== false" (click)="dismiss()" color="dark" icon-only class="close-btn"
    fill="clear" size="small">
    <ion-icon name="close-outline" slot="start"></ion-icon>
  </ion-button>

  <ion-grid>
    <ion-row>
      <ion-col>

        <ion-card-header *ngIf="!!(view.title|translateAndFillVars) || !!(view.subtitle|translateAndFillVars)">
          <ion-card-title *ngIf="!!view.title" [innerHTML]="view.title|translateAndFillVars"></ion-card-title>
          <!--<ion-card-subtitle *ngIf="!!view.subtitle" [innerHTML]="view.subtitle|translateAndFillVars"></ion-card-subtitle>-->

        </ion-card-header>

        <!-- [class.has-lottie]="!!options.path" -->
        <ion-card-content [hidden]="!view.isDesktop"
          *ngIf="!!(view.text|translateAndFillVars)">
          <p *ngIf="!!view.text" [innerHTML]="view.text|translateAndFillVars"></p>
        </ion-card-content>

      </ion-col>

      <ion-col *ngIf="!!options.path" class="lottieCol animationCol visualCol">

        <pipeline-spline-viewer *ngIf="!!view.splineSrc && (!!appConfig.showGetGeniusDaniInSidebar || !view.isDesktop)"
          [class]="view.animationClass" [options]="view.loadingOptions"></pipeline-spline-viewer>

        <!--
        <ng-lottie
          *ngIf="!!view.lottieSrc"
          width="100%"
          height="100%"
          containerClass="intro-card-lottie-wrapper"
          [options]="options"
          (animationCreated)="animationCreated($event)"
          (configReady)="configReady()"
          (dataReady)="dataReady()"
          (domLoaded)="domLoaded()"
          (destroy)="destroy($event)"
          (error)="error($event)">
        </ng-lottie>
        -->

      </ion-col>

    </ion-row>
  </ion-grid>

  <!-- [class.has-lottie]="!!options.path" -->
  <ion-card-content [hidden]="!!view.isDesktop"
    *ngIf="!!(view.text|translateAndFillVars)">

    <p *ngIf="!!view.text" [innerHTML]="view.text|translateAndFillVars"></p>

  </ion-card-content>

</ion-card>