import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BlogService } from 'src/app/services/pipeline/blog.service';
import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';

import { BlogAdminEmployeeMotivationPage } from "../../pages/blog-admin/blog-admin-employee-motivation/blog-admin-employee-motivation.page";

@Injectable({
  providedIn: 'root'
})
export class BlogAdminService {

  view: any = {};

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private config: ConfigService,
    private loading: LoadingController,
    private modalService: ModalService,
    private userService: UserService,
  ) {

  }

  accept(post: post) {
    return new Promise(async (resolve, reject) => {
      if (!!post.category) {
        this.executeAccept(post).then(resolve).catch(reject);
      } else {
        this.loadAcceptCategoriesMenu()
          .then((data) => {
            this.executeAccept(post, data).then(resolve).catch(reject);
          })
          .catch(reject);
      }
    });
  }

  async acceptPostEmployeeMotivation() {

    if (!this.config.getConfigParam('useBlogAdminEmployeeMotivationExtension')) {
      return false;
    }

    let rand = Math.random() < 0.1;

    if (rand) {

      const modal: any = await this.modalService.create({
        component: BlogAdminEmployeeMotivationPage,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });

      this.modalService.present(modal);
    }
  }

  executeAccept(post: post, data: any = null) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      post.category = post.category || (!!data ? data.category : null);
      post.type = 'post';

      this.blog.removePublic();

      delete post.checked;
      delete post.parsed;
      delete post.rating;
      delete post.reactions;
      delete post.relatedArticles;

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          post: post,
          user: this.userService.getUid(),
        },
        ["posts", "accept"]
      )
        .then((response: any) => {
          loading.dismiss();
          this.acceptPostEmployeeMotivation();
          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  loadAcceptCategoriesMenu() {
    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      const blForceRefresh = !this.view.categoriesLoaded;

      this.blog
        .getMainCategories(blForceRefresh, false)
        .then(async (mainCategories: category[]) => {
          loading.dismiss();
          let inputs = [];
          this.view.categoriesLoaded = true;

          mainCategories.forEach((mainCategory: category) => {
            inputs.push({
              name: "mainCategory",
              value: mainCategory.term_id,
              label: mainCategory.name,
              type: "radio",
            });
          });

          this.showCategoriesPickerAlert(inputs, mainCategories)
            .then(async (data: any) => {
              loading.dismiss();
              resolve(data);
            })
            .catch((error: any) => {
              loading.dismiss();
              reject(error);
            });
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  async showCategoriesPickerAlert(inputs: any, mainCategories: any) {
    return new Promise(async (resolve, reject) => {
      const alert: any = await this.alertCtrl.create({
        header: "Kategorie wählen",
        inputs: inputs,
        buttons: [
          {
            text: "Okay",
            role: "submit",
            handler: (data: any) => {
              const categorySelect = mainCategories.filter((_category: category) => {
                return _category.term_id == data;
              }),
                category = categorySelect[0] || null;
              if (!category) {
                reject("error_missing_category_id");
              } else if (
                category &&
                (!category.categories || !category.categories.length)
              ) {
                resolve({
                  category: category.term_id,
                });
              } else {
                this.showSubcategoriesPickerAlert(category)
                  .then(resolve)
                  .catch(reject);
              }
            },
          },
          {
            role: "cancel",
            text: "Abbrechen",
          },
        ],
      });
      alert.onWillDismiss().then((response: any) => {
        if (response.role !== "submit") {
          reject(response);
        }
      });
      await alert.present();
    });
  }

  async showSubcategoriesPickerAlert(mainCategory: any) {
    return new Promise(async (resolve, reject) => {
      let inputs = [];

      mainCategory.categories.forEach((mainCategory: any) => {
        inputs.push({
          name: "mainCategory",
          value: mainCategory.term_id,
          label: mainCategory.name,
          type: "radio",
        });
      });

      const alert: any = await this.alertCtrl.create({
        header: "Unterkategorie wählen",
        inputs: inputs,
        buttons: [
          {
            text: "Okay",
            role: "submit",
            handler: (categoryId: number) => {
              resolve({ category: categoryId || mainCategory.term_id });
            },
          },
          {
            text: "In " + mainCategory.name + " speichern",
            role: "submit",
            handler: () => {
              resolve({ category: mainCategory.term_id });
            },
          },
          {
            role: "cancel",
            text: "Abbrechen",
            handler: reject,
          },
        ],
      });
      await alert.present();
    });
  }

}