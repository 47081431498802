import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class AiPlannerService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  addPostToPlan(postId: number, planId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      plan_uid: planId,
      post_uid: postId,
    }, ['ai', 'plans', 'link']);
  }

  executeCreateCampaign(options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      options: options,
    }, ['ai', 'executeCreateCampaign']);
  }

  async generate(options: any = {}) {
    console.log('ai-planner: generate', options);

    /*
    const schedule: any = this.schedule(this.viewData.plan || {}, {
      channels: (this.viewData.channels || []),
      create_assets: !!this.viewData.create_assets,
      create_creatives: !!this.viewData.create_creatives,
      languages: (this.viewData.languages || []),
      mediaList: (this.viewData.mediaList || []),
      topics: topicsString,
    });
    */
  }

  getPlanByUid(planId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['ai', 'plans', planId], {}, blForceRefresh);
  }

  lazyLoadContent(item: any, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
      options: options,
    }, ['ai', 'lazyLoadContent'], {}, blForceRefresh);
  }

  schedule(plan: aiPlan, config: any = {}, blForceRefresh: boolean = false, params: any = {}) {

    if (!!config) {
      plan.config = config;
    }

    return this.AppCMS.loadPluginData('pipeline', Object.assign((params || {}), {
      plan: plan,
    }), ['ai', 'schedule'], {}, blForceRefresh);
  }

}