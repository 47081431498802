import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SimplyLocalService {

  constructor(

  ) {

  }

  pay() {
    return new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    });
  }

}
