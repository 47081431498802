import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { FoldersService } from 'src/app/services/utils/folders.service';

@Injectable({
    providedIn: 'root'
})
export class CommentsService {

    constructor(
        private AppCMS: AppcmsService,
        private folders: FoldersService,
    ) {

    }

    analyseComments(options: any = {}, blForceRefresh: boolean = false) {
        options = options || {};

        console.log('comments: analyseComment: options', options);

        return this.AppCMS.loadPluginData('pipeline', {
            options: options,
        }, ['comments', 'analyze'], {}, blForceRefresh);
    }

    crawlCommentsByPostUid(postId: number, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['posts', postId, 'comments', 'crawl'], {}, true);
    }

    createCommentReplyMessage(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['comments', 'createReplyMessage'], {}, blForceRefresh);
    }

    createFolder(folder: folder) {
        folder.location = folder.location || 'comment';
        return this.folders.create(folder);
    }

    deleteFolder(folderId: number) {
        return this.folders.delete(folderId);
    }

    getAll(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['comments', 'list'], {}, blForceRefresh);
    }

    getByPostUid(postId: number, options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['posts', postId, 'comments', 'list'], {}, blForceRefresh);
    }

    getComments(options: any = {}, blForceRefresh: boolean = false) {
        return this.getAll(options, blForceRefresh);
    }

    getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
        options.location = options.location || 'comment';
        return this.folders.get(options, blForceRefresh, params);
    }

    reply(data: any = {}, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            comment: data,
        }), ['comments', 'reply'], {}, true);
    }

    submit(data: any = {}, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            comment: data,
        }), ['comments', 'submit'], {}, true);
    }

}