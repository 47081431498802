import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';

@Component({
  selector: 'pipeline-view-mode-picker',
  templateUrl: './view-mode-picker.component.html',
  styleUrls: ['./view-mode-picker.component.scss']
})
export class ViewModePickerComponent implements OnDestroy, OnInit {

  cacheKey: string = 'VIEW_MODE';

  @Input() view: any;

  @Output() changed = new EventEmitter();

  constructor(
    private cache: CacheService,
  ) {
  }

  async loadSettings() {
    try {
      let settings: any = await this.cache.get(this.cacheKey, -1);
      this.view.expertMode = (!!settings && !!settings.data);
    } catch(e) {
      console.log('loading view mode settings failed', e);
    }
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.loadSettings();
  }
  
  toggleView() {
    this.view.expertMode = !this.view.expertMode;
    this.cache.set(this.cacheKey, !!this.view.expertMode);
    this.changed.emit(this.view.expertMode);
  }

}