import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  _detailItem: any | null;

  defaultProductOptimizationPrompt: string = `Create a detailed and comprehensive product description that is fully optimized for search engines based on the provided input.\n\n
Ensure the description is engaging, appealing to read and has a logical flow of information, guiding the reader naturally from one section to the next.\n\n
Use clear and descriptive subheadings (H2, H3) to break down the content – they should market the product.\n\n
Employ bullet points or numbered lists to highlight key features and benefits.\n\n
Include product specifications, use cases, and any unique selling points.\n\n
Use language and tone that reflects the brand's identity.\n\n
Maintain consistency in terminology and phrasing used across the brand's content.\n\n\n
Include relevant LSI (Latent Semantic Indexing) keywords naturally throughout the text. Ensure proper keyword density (1-2% of the total word count).\n\n
Address potential user concerns or objections within the description.\n\n
Highlight any guarantees, return policies, or customer service information.\n\n
Emphasize the product's benefits and how it solves the user's problem or meets their needs.\n\n
Provide a strong, clear CTA to encourage the user to take the next step and ensure the CTA is prominently placed and stands out from the rest of the content.`;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private folders: FoldersService,
    private posts: PostsService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
  ) {

  }

  addSource(source: any = null) {
    return new Promise(async (resolve, reject) => {

    });
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'shop';
    return this.folders.create(folder);
  }

  deleteFolder(folderId: number) {
    return this.folders.delete(folderId);
  }

  deleteSource(sourceId: number) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['shop', 'sources', sourceId, 'delete']);
  }

  detailItem(item: post | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getByUid(sourceId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'sources', sourceId],
      {},
      blForceRefresh
    );
  }

  getCategories(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'categories'],
      {},
      blForceRefresh
    );
  }

  getCategoryByUid(productId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'categories', productId],
      {},
      blForceRefresh
    );
  }

  getDefaultProductOptimizationPrompt() {
    return this.defaultProductOptimizationPrompt;
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.filter = options.filter || {};
    options.filter.location = options.filter.location || 'shop';
    return this.folders.get(options.filter, blForceRefresh, options);
  }

  getFullProducts(products: any) {
    products.forEach((product: any, index: number) => {
      product.id = product.id || (product.original_uid || product.uid);
      product.thumbnail = product.thumbnail || product.image;
      product.images = product.images || [{
        src: product.image,
      }];

      product = this.posts.getFullPost(product);
      product.image = product.thumbnail;
      product.type = product.type !== 'post' ? product.type : 'product';

      products[index] = product;
    });

    products = products.filter((product: product) => {
      return product && product.image && product.image.length;
    });

    return products;
  }

  getProductByUid(productId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'products', productId],
      {},
      blForceRefresh
    );
  }

  getProductsByCategory(categoryId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['shop', 'categories', categoryId, 'products'],
      {},
      blForceRefresh
    );
  }

  getCategoriesBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'categories_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'categories'])
          .then((response: any) => {
            if (response.items) {
              this.cache.set(key, response.items)
                .then(() => {
                  resolve(response.items);
                })
                .catch(() => {
                  resolve(response.items);
                });
            } else {
              reject(response);
            }
          })
          .catch(reject);
      }
    });
  }

  getProducts(filter: any = {}, blForceRefresh: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'products_' + JSON.stringify(filter) + JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['products'])
          .then((products: product[]) => {
            this.cache.set(key, products)
              .then(() => {
                resolve(products);
              })
              .catch(() => {
                resolve(products);
              });
          })
          .catch(reject);
      }
    });
  }

  getProductsBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'products_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'products'])
          .then((response: any) => {
            if (response.items) {
              this.cache.set(key, response.items)
                .then(() => {
                  resolve(response.items);
                })
                .catch(() => {
                  resolve(response.items);
                });
            } else {
              reject(response);
            }
          })
          .catch(reject);
      }
    });
  }

  getShop(blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'pipeline',
        Object.assign(options, {}),
        ['shop'],
        {},
        blForceRefresh
      )
        .then((shopData: shopData) => {

          if (shopData && shopData.sources) {
            shopData.sources.forEach((source: source) => {

              if (source.items && source.items.length) {
                source.items = this.posts.getFullPosts(source.items);
              }

              source.thumbnail = source.thumbnail || (source.items && source.items[0] ? source.items[0].thumbnail : null);
              source.style = {
                'backgroundUrl': source.thumbnail ? this._sanitizer.bypassSecurityTrustStyle(`url('${source.thumbnail}')`) : '',
              };
            });
          }

          resolve(shopData);
        })
        .catch(reject);
    });
  }

  getSources(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      Object.assign(options, {}),
      ['shop', 'sources'],
      {},
      blForceRefresh
    );
  }

  getStatisticsBySource(sourceId: number, filter: any = {}, blForceRefresh: boolean = false, options = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string = 'categories_bySource_' + sourceId + '_' + JSON.stringify(filter) + JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          filter: filter,
          user: this.userService.getUid(),
        }), ['shop', 'sources', sourceId, 'statistics'])
          .then((statistics: any) => {
            this.cache.set(key, statistics)
              .then(() => {
                resolve(statistics);
              })
              .catch(() => {
                resolve(statistics);
              });
          })
          .catch(reject);
      }
    });
  }

  getVariationByProduct(product: product) {
    return new Promise((resolve, reject) => {
    });
  }

  isConfigured(shop: shopSource) {
    return !!(shop && !!shop.credentials && !!shop.credentials.consumer_key && !!shop.credentials.consumer_secret);
  }

  moveFolder(items: any, options: any = {}) {

    if(!!items && (!!items.ID || !!items.uid)) {
      items = [items];
    }

    return this.folders.moveFolder(items as any, 'shop');
  }

  optimizeProductDescription(productId: number, key: string, input_prompt: string) {
    return this.AppCMS.loadPluginData('pipeline', {
      uid: productId,
      key: key,
      input_prompt: input_prompt,
    }, ['shop', 'products', 'optimize']);
  }

  parseCategories(categories: category[]) {
    if (categories && categories.length) {
      categories.forEach((category: any) => {
        category.thumbnail = category.thumbnail || category.image;
        category.style = {
          'backgroundUrl': category.thumbnail ? this._sanitizer.bypassSecurityTrustStyle(`url('${category.thumbnail}')`) : '',
        };
      });

      categories = categories.filter((category: category) => {
        return category && !!category.thumbnail;
      });
    }
    return categories;
  }

}