import { Injectable } from "@angular/core";
import { LoadingController, NavController } from "@ionic/angular";

import { AccountsService } from "src/app/services/core/accounts.service";
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BlogService } from "src/app/services/pipeline/blog.service";
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from "src/app/services/core/events.service";
import { PostsService } from "src/app/services/posts/posts.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TrackingService } from "src/app/services/core/tracking.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class PipelineService {

  private allowedUserGroups: string[] = ['Admin', 'Creator', 'Mitarbeiter', 'Moderator', 'Vertrieb', 'Vorstand'];

  homeData: homeData[] = [];

  view: any = {
    categoriesLoaded: false,
  };

  constructor(
    private accounts: AccountsService,
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private cache: CacheService,
    private events: EventsService,
    private loading: LoadingController,
    private navCtrl: NavController,
    private posts: PostsService,
    private tools: ToolsService,
    private tracking: TrackingService,
    private userService: UserService
  ) {
    this.init();
  }

  authorize(options: any = {}) {
    return this.getHome(true, Object.assign({
      expanded: false,
      categories: false,
      home: false,
      people: false,
      posts: false,
      profile: true,
      stories: false,
    }, options));
  }

  getDiscover(blForceRefresh: boolean = false, params: any = {}) {
    return new Promise(async (resolve, reject) => {
      const user = this.userService.getUser() || {},
        language: string = (this.userService.getLanguage() || "en");

      params.language = params.language || language;
      params.limit = params.limit || 50;

      params = Object.assign(
        params,
        {
          location: false,
          logfile: false,
          uid: user.uid,
        }
      );

      params.user = params.user || this.userService.getApiCredentials();

      const key: string = "pipeline_discover_" + JSON.stringify(params),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        this.setHomeData(fromCache.data, key);
        resolve(fromCache.data);
      } else if (!blForceRefresh && this.homeData[key]) {
        resolve(this.homeData[key]);
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          params,
          ["discover"]
        )
          .then(async (homeData: homeData) => {

            if (homeData.posts && homeData.posts.length) {
              homeData.posts.forEach((post: post, index: number) => {
                post = this.posts.getFullPost(post);
                homeData.posts[index] = post;

                setTimeout(() => {
                  this.posts.cachePost(post, {});
                }, (100 * index));

              });
            }

            if (homeData.categories) {
              homeData.categories = this.blog.parseCategories(
                homeData.categories
              );
            }

            this.cache.set(key, homeData)
              .then(() => {
                this.setHomeData(homeData, key);
                resolve(homeData);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  async getDislikedUids() {
    const fromCache: cacheItem = (await this.cache.get('pipeline_disliked_uids', -1));
    return fromCache && fromCache.data ? fromCache.data : [];
  }

  getHome(blForceRefresh: boolean = false, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      const user = this.userService.getUser() || {},
        language: string = (this.userService.getLanguage() || "en");

      params.language = params.language || language;
      params.limit = params.limit || 50;

      params = Object.assign(
        params,
        {
          location: false,
          logfile: false,
          uid: user.uid,
        }
      );

      params.user = params.user || this.userService.getApiCredentials();

      const key: string = "pipeline_home_" + JSON.stringify(params),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        this.setHomeData(fromCache.data, key);
        resolve(fromCache.data);
      } else if (!blForceRefresh && this.homeData[key]) {
        resolve(this.homeData[key]);
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          params,
          ["home"]
        )
          .then(async (homeData: homeData) => {

            if (homeData.posts && homeData.posts.length) {
              homeData.posts.forEach((post: post, index: number) => {
                post = this.posts.getFullPost(post);
                homeData.posts[index] = post;

                setTimeout(() => {
                  this.posts.cachePost(post, {});
                }, (100 * index));

              });
            }

            this.cache.set(key, homeData)
              .then(() => {
                this.setHomeData(homeData, key);
                resolve(homeData);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  async getLikedUids() {
    const fromCache: cacheItem = (await this.cache.get('pipeline_liked_uids', -1));
    return (fromCache && fromCache.data ? fromCache.data : []);
  }

  getPublicUsers(blForceRefresh: boolean = false, config: any = {}) {
    return this.AppCMS.loadPluginData("pipeline", config, ["users", "public"], {}, blForceRefresh);
  }

  getRegionalSources(blForceRefresh: boolean = false, config: any = {}) {
    config.user = config.user || this.userService.getApiCredentials();
    config.userId = config.userId || this.userService.getUid();

    return this.AppCMS.loadPluginData("pipeline", config, ["sources", "regional"], {}, blForceRefresh);
  }

  init() {
    this.events.subscribe('appcms:user:updated', () => {
      const user = this.userService.getUser();
      if (user && user.uid) {
        this.initBackgroundFetch();
      }
    });
  }

  initBackgroundFetch() {
    setTimeout(() => { this.runBackgroundFetch() }, 5 * 1000);
  }

  login(userData: user, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      let validate: boolean = true;

      try {
        if (!!options.api_url) {
          let validate: any = await this.validateAPIUrl(options.api_url);

          if (!!validate) {
            this.AppCMS.setApiUrl(options.api_url);
          }

        }
      } catch (e) {
        validate = false;
        loading.dismiss();
        console.warn('api url validation failed', e);
        reject(e);
        return false;
      }

      if (!validate) {
        loading.dismiss();
        reject('error_validation_failed');
        return false;
      }

      this.AppCMS.postPluginData("pipeline", "login", {
        user: userData,
        uid: this.userService.getUid(),
        expanded: false,
        //language: this.userService.getLanguage(),
        categories: false,
        home: false,
        people: false,
        posts: false,
        stories: false,
      })
        .then((response: any) => {
          loading.dismiss();

          if (response.success) {
            this.validateUser(response.user, false)
              .then(() => {
                this.setHomeData(response);

                response.user.password = userData.password;

                this.accounts.add(response.user);
                this.userService.setUser(response.user);

                this.events.publish("appcms:user:loggedin");

                if (this.userService.shouldConnectWithSIWA()) {
                  setTimeout(() => {
                    this.userService.connectWithSIWA({}, 2);
                  }, 1000);
                }

                setTimeout(() => {
                  try {
                    this.tracking.askTrackingPermission();
                  } catch (e) {
                    console.warn('> tracking prompt error', e);
                  }
                }, 3000);

                resolve(response);
              })
              .catch((error) => {
                loading.dismiss();
                reject(error);
              });
          } else {
            reject(
              response.message || "Ein unbekannter Fehler ist aufgetreten"
            );
          }
        })
        .catch(reject);
    });
  }

  move(postId: number) {
    return new Promise((resolve, reject) => {
      console.log("move", postId);
    });
  }

  rejectProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['shop', 'products', 'reject']);
  }

  runBackgroundFetch() {
    this.getHome()
      .then((homeData: homeData) => {
        if (homeData && homeData.posts && homeData.posts.length) {
          homeData.posts.forEach((blogEntry: post, blogIndex: number) => {
            setTimeout(() => {
              if (blogEntry && blogEntry.relatedArticles && blogEntry.relatedArticles.length) {
                blogEntry.relatedArticles.forEach((relatedArticleId: any, index: number) => {
                  setTimeout(() => {
                    if (typeof relatedArticleId === 'number') {
                      this.posts.getPostByUid(relatedArticleId)
                        .catch((error: any) => {
                          console.log('preload error on relatedArticleId ', relatedArticleId, error);
                        });
                    }
                  }, index * 1500);
                });
              }
            }, blogIndex * 2500);
          });
        }
      })
      .catch((error: any) => {
        console.error('background fetch error', error);
      });
  }

  setDislikedUids(uids: number[]) {
    return this.cache.set('pipeline_disliked_uids', uids);
  }

  setHomeData(data: homeData, key: string = '') {
    this.homeData[key] = data;

    // cache posts
    let blogKeys = ["blog", "external", "regional"];
    blogKeys.forEach((blogKey: string) => {
      if (data.posts && data.posts[blogKey]) {
        data.posts[blogKey].forEach((post: post, index: number) => {
          post = this.posts.getFullPost(post);
          data.posts[blogKey][index] = post;

          setTimeout(() => {
            this.posts.cachePost(post, {});
          }, (100 * index));

        });
        data.posts[blogKey] = this.tools.shuffle(data.posts[blogKey]);
      }
    });

    // cache people
    if (data.people && data.people.length) {
      data.people.forEach((person: user) => {
        this.cache.set('user_' + person.uid, person);
      });
    }

  }

  setLikedUids(uids: number[]) {
    return this.cache.set('pipeline_liked_uids', uids);
  }

  async validateAPIUrl(apiUrl: string) {
    return new Promise((resolve, reject) => {
      let blSuccess: boolean = false;

      this.AppCMS.loadPluginData('config', {}, ['plugins'], { apiUrl: apiUrl })
        .then(() => {
          blSuccess = true;
          resolve(blSuccess);
        })
        .catch(reject);
    });
  }

  validateUser(user: user, blRedirect: boolean = true) {
    return new Promise((resolve, reject) => {
      let error: string | null = null;
      user = (user || this.userService.getUser());

      if (this.userService.isPublicRoute()) {
        resolve(user);
      } else
        if (!user || !user.uid) {
          error = 'error_missing_user_uid';
        } else
          if (!user.classifications || !user.classifications.type) {
            error = 'error_missing_user_type';
          } else
            if (this.allowedUserGroups.indexOf(user.classifications.type) === -1) {
              error = 'error_user_group_not_allowed';
            } else {
              resolve(user);
            }

      if (!!error) {
        console.warn('> validate error', error);

        if (!!blRedirect) {
          this.userService.logout(user);
          this.navCtrl.navigateRoot('/login', { animated: false });
        }

        reject(error);
      }
    });
  }

}