<ion-toolbar *ngIf="!view.hideUI" class="container headerItem ion-no-border">

  <ion-button class="upBtn" *ngIf="state && !!state.subMode" fill="clear" (click)="up()" slot="start" icon-only>
    <ion-icon color="dark" name="arrow-undo-outline"></ion-icon>
  </ion-button>

  <ion-title *ngIf="(view && view.title) || (state && state.title)"
    [class]="(state && state.subMode) ? 'has-sub-mode' : ''" size="large">

    <ion-label [innerHTML]="(view.title || state.title)|translate"></ion-label>

    <pipeline-header-add-button *ngIf="!!appConfig.showHeaderAddButton" [(view)]="view"></pipeline-header-add-button>

    <ion-button *ngIf="view && (view.route === 'local')" size="small" color="dark" id="switchCityBtn"
      (click)="selectCity()" icon-only shape="round" fill="clear">
      <ion-icon name="chevron-down-outline"></ion-icon>
    </ion-button>

  </ion-title>

  <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" *ngIf="state && state.selectData" [(ngModel)]="state.selectData.selection" (ionChange)="onSlideSelectDataChanged(state, $event)">
    <ion-select-option *ngFor="let option of state.selectData.options" [value]="option.value" [innerHTML]="option.label"></ion-select-option>
  </ion-select>

  <ion-button *ngIf="!view.showProjectsSelect && !view.hideOrderByBtn" [hidden]="!!view.hideOrderByBtn" size="small" color="dark" class="orderByBtn" (click)="changeOrderBy()" icon-only shape="round" fill="clear">
    <ion-icon name="options-outline"></ion-icon>
  </ion-button>

  <ion-buttons slot="end" *ngIf="(!!appConfig.useGetGeniusExtension && !view.hideGetGeniusWallet) || (!!appConfig.useProjectsExtension && !!view.showProjectsSelect)">
    <getgenius-tokens-badge [view]="view" *ngIf="!!appConfig.useGetGeniusExtension && !!appConfig.useGetGeniusWalletExtension && !view.hideGetGeniusWallet"></getgenius-tokens-badge>
    <pipeline-project-select *ngIf="!!appConfig.useProjectsExtension && !!view.showProjectsSelect"></pipeline-project-select>
  </ion-buttons>

</ion-toolbar>