import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { GetgeniusDaniComponent } from './getgenius-dani.component';

import { TranslateModule } from '@ngx-translate/core';

import { IntroCardComponentModule } from 'src/app/components/intro-card/intro-card.component.module';
import { SplineViewerComponentModule } from 'src/app/components/spline/spline-viewer/spline-viewer.component.module';
import { RateItemButtonsComponentModule } from 'src/app/components/ratings/rate-item-buttons/rate-item-buttons.component.module';

@NgModule({
  declarations: [
    GetgeniusDaniComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    IntroCardComponentModule,
    SplineViewerComponentModule,
    RateItemButtonsComponentModule,
  ],
  exports: [
    GetgeniusDaniComponent,
  ]
})
export class GetgeniusDaniComponentModule { }