import { Injectable } from "@angular/core";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
//import { ModalService } from "src/app/services/core/modal.service";

//import { CreateAdPage } from "../pages/ads/create-ad/create-ad.page";
//import { MarketplaceEntryPage } from "../pages/marketplace/marketplace-entry/marketplace-entry.page";
//import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { ConfigService } from 'src/app/services/core/config.service';
//import { PostsAdminService } from "src/app/services/posts-admin.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {

  /*
  handler: any = {
    aiQuick: () => {
        this.modalService.closeAll();
        this.ai.quick();
    },
    createAdPage: async () => {
      this.modalService.closeAll();

      const modal: any = await this.modalService.create({
          component: CreateAdPage,
          componentProps: {
              modalService: this.modalService,
          },
          animated: (window.innerWidth <= 768),
          swipeToClose: (window.innerWidth <= 768),
          presentingElement: await this.modalService.getTop(),
          cssClass: 'defaultModal',
      });
      this.modalService.present(modal);
    },
    createPost: async () => {
      this.modalService.closeAll();
      this.postsAdmin.create();
    },
    newMarketplaceEntry: async () => {
      this.modalService.closeAll();

      const modal: any = await this.modalService.create({
          component: MarketplaceEntryPage,
          componentProps: {
              mode: "create",
              modalService: this.modalService.getController(),
              entry: {},
          },
          animated: (window.innerWidth <= 768),
          swipeToClose: (window.innerWidth <= 768),
          presentingElement: await this.modalService.getTop(),
          cssClass: "defaultModal",
      });
      this.modalService.present(modal);
    },
    newPost: async () => {
      this.modalService.closeAll();
      this.postsAdmin.create();
    },
  };
  */

  appPages: any;

  blIsModal: boolean;

  current: page;

  constructor(
    //private ai: AiToolsService,
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private config: ConfigService,
    //private modalService: ModalService,
    //private postsAdmin: PostsAdminService,
  ) {
    this.appPages = this.config.getConfigParam('appPages');
  }

  create(page: page) {
    return this.AppCMS.loadPluginData(
      "pages",
      {
        page: page,
      },
      ["create"]
    );
  }

  delete(pageId: number) {
    return this.AppCMS.loadPluginData(
      "pages",
      {},
      ["delete", pageId]
    );
  }

  getAll(options: any = null, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", {}, [], {}, blForceRefresh);
  }

  getAppPages(key: string) {
    return this.appPages[key] || [];
  }

  getAppPagesKeys() {
    return Object.keys(this.appPages);
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", {}, [uid], {}, blForceRefresh);
  }

  getByCategory(categoryId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", { category: categoryId }, [], {}, blForceRefresh);
  }

  getCurrent() {
    return this.current;
  }

  getHandler(key: string) {
    return null; // this.handler[key];
  }

  getParentMenuName(menuName: string) {
    let parentMenuName: string|null = null;
    let appPageMainNames: string[] = Object.keys(this.appPages);

    if(appPageMainNames && appPageMainNames.length) {
      appPageMainNames.forEach((appPageMainName: string) => {
        let appPages: appPage[] = this.appPages[appPageMainName];

        if(appPages && appPages.length) {
          appPages.forEach((appPage: appPage) => {
            if(appPage.key === menuName && (appPageMainName !== appPage.key)) {
              parentMenuName = appPageMainName;
            }
          });
        }
      });
    }

    return parentMenuName;
  }

  isModal(bl: boolean|null = null) {
    if (bl !== null) {
      this.blIsModal = bl;
    }
    return this.blIsModal;
  }

  async pick(options: any = null) {
    options = options || {};

    const chooseConfig: chooseConfig = Object.assign(options, {
      data: await this.getAll(),
      labelKey: 'name',
      service: this,
      valueKey: 'uid',
    });

    return this.chooser.choose(chooseConfig);
  }

  setCurrent(page: page) {
    this.current = page;
  }

  update(page: page) {
    return this.AppCMS.loadPluginData(
      "pages",
      {
        page: page,
      },
      ["update"]
    );
  }

}