// angular & ionic
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';

// global
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';

// http
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { httpCacheInterceptor } from './interceptors/http-cache.interceptor';

// ionic-native extension
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Brightness } from '@awesome-cordova-plugins/brightness/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { InAppReview } from '@awesome-cordova-plugins/in-app-review/ngx';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { PayPal } from '@ionic-native/paypal/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { from } from 'rxjs';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

// dependencies / libraries
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { TagInputModule } from 'ngx-chips';
import { register } from 'swiper/element/bundle';

// lottie
//import { LottieModule } from 'ngx-lottie';
//import player from 'lottie-web';

//export function playerFactory() {
//  return player;
//}

// angular calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// getgenius + pipeline
import { GetgeniusDaniComponentModule } from 'src/app/components/getgenius/getgenius-dani/getgenius-dani.component.module';
import { CrudService } from "src/app/services/core/crud.service";

// electron
//import { NgxElectronModule } from 'ngx-electron';

// stripe
//import { NgxStripeModule } from 'ngx-stripe';

import { DragulaModule } from 'ng2-dragula';

register();

class CustomLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: CustomLoader,
        //useFactory: (createTranslateLoader),
      }
    }),
    IonicModule.forRoot({
      scrollAssist: false,
      scrollPadding: true,
    }),
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DragulaModule.forRoot(),
    //LottieModule.forRoot({ player: playerFactory }),
    TagInputModule,
    GetgeniusDaniComponentModule,
    ScrollingModule,
    ColorPickerModule,
    AppRoutingModule,
  ],
  providers: [
    Badge,
    Brightness,
    Camera,
    Contacts,
    Crop,
    Facebook,
    File,
    FileTransfer,
    FilePath,
    Geolocation,
    InAppPurchase2,
    InAppReview,
    LaunchReview,
    LocalNotifications,
    NativeGeocoder,
    Network,
    PhotoViewer,
    PayPal,
    SignInWithApple,
    SocialSharing,
    TextToSpeech,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CrudService,
    /*
    provideHttpClient(
      withInterceptors([
        ...
        httpCacheInterceptor,
      ]),
    )
    */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }