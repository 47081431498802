import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private translations: TranslationService,
  ) {
  }

  init() {
    this.initEvents();
  }

  initEvents() {
    this.initOnAlertEvent();
  }

  initOnAlertEvent() {

    this.events.subscribe("alert", async (alertConfig: alertConfig) => {
      
      let alertData = {
        header: alertConfig.header || "Fehler",
        message:
          alertConfig.message || "Ein unbekannter Fehler ist aufgetreten",
        buttons: alertConfig.buttons || [
          {
            text: "Okay",
          },
        ],
      };

      const alert: any = await this.alertCtrl.create(alertData);
      alert.present();
    });

  }

  requestConfirm(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      console.log('alert service: requestConfirm', options);

      let translationKeys: string[] = [
        'cancel',
        'confirm',
        'request_confirm_headline',
        'request_confirm_headline_message',
      ];

      if(!!options.header) {
        translationKeys.push(options.header);
      }

      if(!!options.message) {
        translationKeys.push(options.message);
      }

      this.translations.get(translationKeys)
      .subscribe(async (translations: any) => {

        let alertData = {
          header: (!!options.header ? (translations[options.header] || options.header) : (translations.request_confirm_headline || "request_confirm_headline")),
          message: (!!options.message ? (translations[options.message] || options.message) : (translations.request_confirm_headline_message || "request_confirm_headline_message")),
          buttons: options.buttons || [
            {
              color: 'danger',
              handler: () => {
                resolve(true);
              },
              role: 'confirm',
              text: translations.confirm || "confirm",
            },
            {
              handler: () => {
                reject(false);
              },
              role: 'cancel',
              text: translations.cancel || "cancel",
            },
          ],
        };

        const alert: any = await this.alertCtrl.create(alertData);

        alert.present();

      });
      
    });
  } 

}
