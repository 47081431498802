import { Injectable } from "@angular/core";

import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class ViewService {

  constructor(
    private modalService: ModalService,
    private tools: ToolsService,
    private userService: UserService,
  ) {

  }

  calcScreenSizeVars(view: any = null) {
    view = view || {};
    view.isDesktop = this.tools.isDesktop();

    return view;
  }

  calcVars(view: any = null) {
    view = view || {};

    view.isAdmin = this.userService.isType('Admin');
    view.isModal = this.modalService.isModal();
    view.isWeb = this.tools.isWeb();

    return this.calcScreenSizeVars(view);
  }

}