import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

import { TranslateService } from "@ngx-translate/core";

import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  defaultLanguage: string = 'en';

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private events: EventsService,
    private translate: TranslateService,
    private userService: UserService,
  ) {
    this.setAppCMSLanguageRequestParam();
  }

  applyLanguage() {
    const language = this.userService.getLanguage();
    
    this.translate.use(language);
    moment.locale(language);
    
    const user: user = this.userService.getUser() || {};

    if (user && user.uid) {
      this.events.publish("home:refresh");
    }
  }

  getCurrentLanguage() {
    const user: user = this.userService.getUser() || {};
    return user && user.classifications ? (user.classifications.language || this.defaultLanguage) : this.defaultLanguage;
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  getLanguages(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", {}, ["languages"], {}, blForceRefresh)
      .then((languages: language[]) => {

        if(languages && languages.length) {
          languages.forEach((language: language) => {
            language.flag = './assets/img/flags/' + language.indent + '.svg';
          });
        }
        
        resolve(languages);
      })
      .catch(reject);
    });
  }

  init() {
    this.initEvents();
    this.initLanguage();

    this.applyLanguage();
  }

  initEvents() {

    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.setAppCMSLanguageRequestParam();
    });

  }

  initLanguage() {
    try {
      this.translate.setDefaultLang("en");

      const user: user = this.userService.getUser() || {},
            userLanguage: string|null = (!!user && !!user.classifications && !!user.classifications.language ? user.classifications.language : null),
            language: string = (userLanguage || (window.navigator.language || "en").split("-")[0]);

      if (language) {
        this.translate.use(language);
        moment.locale(language);
      } else {
        moment.locale("en");
      }

    } catch (e) {
      console.warn("language error", e);
      moment.locale("de");
    }
  }

  pick(options: any = null) {
    return new Promise(async (resolve, reject) => {
      const data: any = (!!options.data && !!options.data.length ? options.data : await this.getLanguages());
      
      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: data,
        labelKey: 'name',
        multiple: !!options.multiple,
        service: this,
        title: (!!options.multiple ? 'languages' : 'language'),
        valueKey: 'indent',
      });
      
      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  setAppCMSLanguageRequestParam() {
    this.AppCMS.setRequestParam('language', `${this.getCurrentLanguage() || 'en'}`);
    this.initLanguage();
  }

  useTranslator() {
    return this.getCurrentLanguage() !== this.getDefaultLanguage();
  }

}